// 不同的域名接口
const publicApiUrl1 = 'https://webapi.share.zsjykj.net/';
const publicApiUrl2 = 'https://www.ylxue.net/';
const publicApiUrl3 = 'https://api.rs.admin.zsjykj.net/';

// 统一存放接口
const publicApiUrl = {
    GetNoticePage: publicApiUrl2 + 'Notice/GetNoticePage', // 首页公告接口
    GetRecommendResource : publicApiUrl1 + 'GetRecommendResource', // 首页课程分类数据接口
    GetAllCategory: publicApiUrl1 + 'GetAllCategory', // 资源中心课程分类接口
    GetResource: publicApiUrl1 + 'GetResource',  // 资源中心查询课程
    GetNoticeById: publicApiUrl2 + 'Notice/GetNoticeById', // 平台公告公告详情
    GetCustomerResourceByCode: publicApiUrl1 + 'GetCustomerResourceByCode',  // 资源中心课程详情课程信息
    SendMobileVerificationCode: publicApiUrl1 + 'SendMobileVerificationCode',  // 发送验证码
    AddUser: publicApiUrl1 + 'AddUser', // 个人注册
    login: publicApiUrl1 + 'login', // 登录
    SendVerificationCode: publicApiUrl1 + 'SendVerificationCode', // 个人中心基本信息发送验证码
    VaildMobile: publicApiUrl1 + 'VaildMobile',  // 个人中心基本信息验证手机
    ModifyMobile: publicApiUrl1 + 'ModifyMobile', // 个人中心基本信息修改手机
    UpdateUserPaassword: publicApiUrl1 + 'UpdateUserPaassword',  // 个人中心基本信息登录密码修改
    GetAliInfo: publicApiUrl1 + 'Platform/GetAliInfo', // 阿里云上传图片获取参数
    AddUserIdentity: publicApiUrl1 + 'AddUserIdentity', // 发布视频个人提交发布申请
    GetUserIdentity: publicApiUrl1 + 'GetUserIdentity', // 个人中心基本信息编辑扩展信息个人获得数据
    UpdateUserIdentity: publicApiUrl1 + 'UpdateUserIdentity',  // 个人中心基本信息个人编辑扩展信息保存数据
    GetUserPostVideoAuditStaus: publicApiUrl1 + 'GetUserPostVideoAuditStaus', // 个人中心基本信息查询审核状态
    UserCourseQuery: publicApiUrl1 + 'UserCourse/Query', // 个人中心基本信息发布视频查询课程列表
    UserCourseAdd: publicApiUrl1 + 'UserCourse/Add', // 个人中心基本信息发布视频添加课程
    UserCourseDelete: publicApiUrl1 + 'UserCourse/Delete',  // 个人中心基本信息发布视频删除课程
    UserCourseUpdate: publicApiUrl1 + 'UserCourse/Update', // 个人中心基本信息发布视频编辑课程
    UserCourseAddClasses: publicApiUrl1 + 'UserCourse/AddClasses',  // 个人中心基本信息发布视频添加课时
    GetAWSInfo: publicApiUrl1 + 'Platform/GetAWSInfo', // 白山云上传视频获取参数
    UserCourseGet: publicApiUrl1 + 'UserCourse/Get',  // 个人中心基本信息发布视频查询课时列表
    UserCourseUpdateClasses: publicApiUrl1 + 'UserCourse/UpdateClasses',  // 个人中心基本信息发布视频编辑课时
    UserCourseDeleteClasses: publicApiUrl1 + 'UserCourse/DeleteClasses',   // 个人中心基本信息发布视频删除课时
    GetUserUnit: publicApiUrl1 + 'GetUserUnit',  // 个人中心基本信息编辑扩展信息合作商获得数据
    AddUserUnit: publicApiUrl1 + 'AddUserUnit',  // 发布视频采购商和合作机构提交发布申请
    UpdateUserUnit: publicApiUrl1 + 'UpdateUserUnit',  // 个人中心基本信息编辑扩展采购商和合作机构保存数据
    AddCustomerResource: publicApiUrl1 + 'AddCustomerResource', // 资源中心课程详情选课
    RetrievePassword: publicApiUrl1 + 'RetrievePassword',  // 忘记密码
    GetCustomerResource: publicApiUrl1 + 'GetCustomerResource', // 个人中心我的选课获取已选课列表
    BatchAddCustomerResource: publicApiUrl1 + 'BatchAddCustomerResource',  // 个人中心我的选课保存选择的课程
    DeleteCustomerResourc: publicApiUrl1 + 'DeleteCustomerResourc',  // 个人中心我的选课删除已选择的课程
    Statistics_GetCoustomerCourseReport: publicApiUrl3 + 'Statistics/GetCoustomerCourseReport', // 个人中心资源使用统计查询用户资源使用量
    GetCoustomerCourseDetailReport: publicApiUrl3 + 'Statistics/GetCoustomerCourseDetailReport', // 个人中心资源使用统计查询课程
    GetAppKey: publicApiUrl1 + 'GetAppKey',  // 个人中心基本信息查看密钥
    GenerateAppKey: publicApiUrl1 + 'GenerateAppKey',  // 个人中心基本信息生成密钥
    GetCategoryData: publicApiUrl1 + 'GetCategoryData', // 个人中心我的选课获取专业
    Admin_GetOperateNotices: publicApiUrl1 + 'GetOperateNotices', // 个人中心消息中心消息列表
    GetAllDomainAuth: publicApiUrl1 + 'DomainAuth/GetAllDomainAuth', // 个人中心基本信息获取授权域名列表
    Delete: publicApiUrl1 + 'DomainAuth/Delete', // 个人中心基本信息删除域名
    Add: publicApiUrl1 + 'DomainAuth/Add' // 个人中心基本信息新增域名,
}
// 正则
const publicRegular = {
    password: /^(?!(?:\d*$))[A-Za-z0-9]/, // 验证密码正则,至少需要一个数字
}
export default {  // 添加export抛出模块
    publicApiUrl: publicApiUrl,
    publicRegular: publicRegular,
}