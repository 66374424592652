<template>
	<div class="aspect-ratio" :class="ratio?['enable-ratio']:[]" :style="style">
		<div class="content">
			<slot></slot>
		</div>
	</div>
</template>

<script setup lang="ts" name="aspectRatio">
import {computed, defineProps} from 'vue'

interface AspectRatioProps {
	width?: string | number;
	ratio?: number | string;
}

const props = defineProps<AspectRatioProps>()
const style = computed(() => {
	const style: any = {}
	if (props.width) {
		let width = props.width + ''
		if (width.includes('%')) {
			style.width = width
		} else if (width.includes('px')) {
			style.width = width
		} else {
			style.width = width + 'px'
		}
	}
	if (props.ratio) {
		let ratio = props.ratio + ''
		if (!ratio.includes('%')) {
			ratio = (parseFloat(ratio) * 100).toFixed(2) + '%'
		}
		style['--aspect-ratio'] = ratio
	}
	return style
})
</script>

<style scoped lang="scss">
.aspect-ratio {
	position: relative;
	box-sizing: border-box;
	
	.content {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		box-sizing: border-box;
	}
}

.enable-ratio {
	--aspect-ratio: 50%;
	
	&:before {
		content: '';
		display: block;
		padding-top: var(--aspect-ratio);
	}
}
</style>