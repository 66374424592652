<template>
	<div class="footer">
		<p class="footer_about">
			<router-link v-for="item in data.aboutItem" :key="item" :to="item.link">{{ item.name }}</router-link>
		</p>
<!--		<p>版权所有：北京中盛佳源教育科技有限公司</p>-->
		<p class="footer_link">
			<a href="http://beian.miit.gov.cn">京ICP备15052609号-2</a>
		</p>
		<p style="display: flex; align-items: center; justify-content: center; margin-top: 20px;">
			<img src="https://img.alicdn.com/tfs/TB13DzOjXP7gK0jSZFjXXc5aXXa-212-48.png" style="width: 70px; margin-right: 10px;">
			提供计算与安全服务
		</p>
	</div>
</template>

<script lang="ts" setup>
import {reactive} from 'vue';

const data: any = reactive({
	aboutItem: [
		{
			'name': '加入我们',
			'link': '/666',
		},
		{
			'name': '关于我们',
			'link': '/777',
		},
		{
			'name': '联系我们',
			'link': '/888',
		},
	],
});
</script>
<style scoped lang="scss">
.footer {
	text-align: center;
	background-color: rgb(68, 142, 237);
	padding: 22px 0 45px;
	min-width: 1024px;
	box-sizing: border-box;
	
	p {
		color: #fdfafa;
		font-size: 16px;
		
		a {
			color: #fdfafa;
		}
	}
	
	.footer_about {
		margin-bottom: 20px;
		display: none;
	}
	
	.footer_about a {
		border-right: 1px solid #fdfafa;
		padding: 0 10px;
	}
	
	.footer_about a:last-child {
		border-right: none;
	}
	
	.footer_link {
		margin-top: 20px;
	}
}
</style>