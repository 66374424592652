<template>
	<Header/>
	<div class="main">
		<router-view/>
	</div>
	<Footer/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
	name: 'HomeView',
	components: {
		Header,
		Footer
	},
});
</script>
<style lang="scss">
.main {
	//padding: 0 50px;
	min-width: 1024px;
	box-sizing: border-box;
}
</style>
